import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_BASE_PATH;

export const instance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
});

const httpClient = async (url, options = {}) => {
  let access_token = localStorage.getItem('auth');

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Access-Control-Allow-Origin', '*');
  options.headers.set('Access-Control-Expose-Headers', 'content-range');
  options.headers.set('Authorization', `Bearer ${access_token?.slice(1, -1)}`);
  instance.defaults.headers.common.Authorization = `Bearer ${access_token?.slice(
    1,
    -1
  )}`;
  const fetchJsonUrl = fetchUtils.fetchJson(url, options);
  return fetchJsonUrl;
};

export default {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort_by: field,
      ascending: order === 'ASC' ? true : false,
      limit: perPage,
      offset: (page - 1) * perPage,
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, {}).then(({ headers, json }) => ({
      data: json.results,
      total: json.paging.total,
    }));
  },

  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return httpClient(url, {}).then(({ headers, json }) => {
      return { data: json };
    });
  },

  getResource: (resource) => {
    const url = `${apiUrl}/${resource}`;
    return instance.get(url);
  },

  getPayment: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return instance.get(url);
  },

  post: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    return instance.post(url, params);
  },

  getMerchants: () => {
    const url = `${apiUrl}/merchants`;
    return httpClient(url, {}).then(({ headers, json }) => {
      return { data: json };
    });
  },

  getMerchantMe: () => {
    const url = `${apiUrl}/merchants/me`;
    return httpClient(url, {}).then(({ headers, json }) => {
      return { data: json };
    });
  },

  getCurrencies: async (
    params = {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'ASC' },
    }
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort_by: field,
      ascending: order === 'ASC' ? true : false,
      limit: perPage,
      offset: (page - 1) * perPage,
      filter: JSON.stringify(params?.filter),
    };
    const url = `${apiUrl}/currencies?${stringify(query)}`;
    return httpClient(url, {}).then(({ headers, json }) => {
      return { data: json };
    });
  },
  getStatistics: async (params) => {
    if (params) {
      const query = {
        filter: JSON.stringify(params.filter),
      };
      const url = `${apiUrl}/transactions/statistics?${stringify(query)}`;
      return httpClient(url, {}).then(({ headers, json }) => {
        return { data: json };
      });
    } else {
      const url = `${apiUrl}/transactions/statistics`;
      return httpClient(url, {}).then(({ headers, json }) => {
        return { data: json };
      });
    }
  },
  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return { data: json.results };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url);
    return {
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    };
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  put: async (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    const { json } = await httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: 'DELETE',
    });
    return { data: json };
  },
};
