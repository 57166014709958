import { Tooltip } from '@mui/material';
import React from 'react';
import { USD_CURRENCY } from '../../assets/constants/TOKENS';

const formatCurrencies = (currencies, isTransaction) => {
  if (isTransaction) {
    return currencies.map(curr => ({ logo: curr.logo, ticker: curr.ticker }));
  }
  return currencies.map(curr => ({ logo: curr.currency_logo, ticker: curr.ticker }));
};

const filterEnabled = (currencies) => {
  if (!currencies) return [];
  return formatCurrencies(currencies.filter(curr => curr.enabled));
};

const filterTransactions = (record, currencies) => {
  if ((record.transactions?.length < 1 && !record.card_payment) || currencies?.length < 1) return [];
  if (record.card_payment) {
    return [USD_CURRENCY];
  }
  const currenciesInTxs = record.transactions.map((tx) => currencies.find(curr => tx.coin.toLowerCase() === curr.ticker.toLowerCase()));
  return formatCurrencies(currenciesInTxs, true);
};

export const TokenFieldList = ({ record, currencies, isMerchantList }) => {

  if (record.status && record.status !== 'approved') return;

  const enabledCurrencies = isMerchantList ? filterEnabled(record.currencies) : filterTransactions(record, currencies);

  return (
    <div style={styles.container}>
      {enabledCurrencies.map((curr, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: index !== 0 ? '-10px' : '0',
          }}
        >
          <Tooltip title={curr?.ticker} arrow>
            <img src={curr?.logo} alt={curr?.ticker} style={styles.logo} />
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  logo: {
    width: '30px',
    height: '30px',
    flexShrink: 0
  }
};
