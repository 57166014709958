import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import './style.css';
import { useListContext } from 'react-admin';

const Input = ({ sx, label, ...rest }) => {
  const [value, setValue] = React.useState('');
  const { filterValues, setFilters } = useListContext();
  const handleFilter = (e) => {
    setFilters && setFilters({ ...filterValues, q: e.target.value });
    setValue(e.target.value);
  };
  return (
    <TextField
      label={''}
      variant="outlined"
      placeholder={label}
      size='small'
      color='secondary'
      onChange={handleFilter}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#FFF',
          borderRadius: '16px',
          paddingLeft: '6px',

        },
        '& input': {
          padding: '5px 14px 5px 0px',
          fontSize: '15px',
          color: 'rgba(0,0,0,.6)'

        },
        '& label': {
          top: '-6px',
          fontSize: '12px'
        },
        ...sx
      }}
      {...rest}
    />
  );
};

export const InputNumberMP = ({ sx, value, ...rest }) => {
  const width = Math.max(5.7, value.length + 2);

  return (
    <TextField
      {...rest}
      value={value}
      variant="outlined"
      size='small'
      fullWidth
      label={''}
      InputProps={{
        maxLength: 12,
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyRoundedIcon sx={{ fontSize: '36px' }} />
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 12,
      }}
      sx={{
        padding: 0,
        width: 'auto',
        '& .MuiOutlinedInput-root': {
          width: width + 'ch',
          maxWidth: '330px',
          fontSize: '39px',
          fontWeight: '500',
          margin: '0 auto',
          padding: '0px'
        },
        '& .MuiOutlinedInput-input': {
          padding: '0px'
        },
        '& fieldset': {
          border: 'none',
          padding: 0
        },
        ...sx
      }}
    />
  );
};

export default Input;