
import Card from '../../Components/Card/Card';
import { ChartLineComponent } from './Components/ChartLineComponent';
import { ChartPieComponent } from './Components/ChartPieComponent';
import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { DashbordFilters } from './Components/DashboardFilters';
import { Box, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useDataProvider, usePermissions } from 'react-admin';
import { useQuery } from 'react-query';
import { MdHomeWork } from 'react-icons/md';


export const DashboardList = () => {
  const [filters, setFilters] = useState({
    'from_date': dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    'to_date': dayjs().format('YYYY-MM-DD')
  });
  const [merchant, setMerchant] = useState(null);
  const { permissions } = usePermissions();

  const dataProvider = useDataProvider();
  const { refetch, data } = useQuery(['merchants'],() => dataProvider.getMerchants(), {
    enabled: permissions === 'superadmin'
  } );

  const selectMerch = (e) => {
    setMerchant(e.target.value);
  };
  console.log(merchant);

  return (
    <>
      {
       
        permissions === 'superadmin' && !merchant ? (
          <MerchantMenu selectMerch={selectMerch} permissions={permissions} merchants={data?.data?.results} />
        ) : (
       
   
          <Box key="dashboard-Box" sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto'
          }}>
     
            <Box mt={2} sx={{width: 'auto', marginX: '100px', gap: '10px', display: 'flex', flexDirection: 'column'}} title="Dashboard">
              <Typography sx={{
                fontSize: '2.1em',
                fontWeight: '700',
                color: 'primary',
                alignSelf: 'flex-start'
              }}>Dashboard</Typography>
    
              <DashbordFilters  initialValue={data?.data?.results?.find(item => item.id === merchant)} merchants={data} filters={filters} setFilters={setFilters} />
              <Card 
                border
                sxHeader={{
                  width: 'auto',
                }}
                title="Total ventas"
                sx={{
                  gap: '100px',
                  display: 'flex',
                  flexWrap: 'wrap', 
                  height:'auto', 
                  flexDirection : 'row',
                  justifyContent: 'flex-start', 
                  alignItems: 'center', 
                  width: 'auto',
            
                }}>
                <ChartLineComponent filters={filters} options={{plugins: {legend: {display: false}}}} />
              </Card>
              <Card sxHeader={{
                width: 'auto', 
                maxWidth: '700px',

              }} sx={{width: 'auto'}} border title="Total por coins">
                <ChartPieComponent filters={filters} />
              </Card>
            </Box>
    
          </Box>
        )
      
      }</>
  );
};



export const MerchantMenu = ({ merchants, permissions, selectMerch }) => {

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <MdHomeWork size="300px" />
      <Typography sx={{
        fontSize: '1.1em',
        fontWeight: '600',
        
      }}> Selecciona un negocio para visualizar el dashboard</Typography>
      {
        merchants ? (
          <TextField
            select
            sx={{
              width: '500px'
            }}
            onChange={selectMerch}
            variant="outlined">
            {
              merchants?.map((item, index) => (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))
            }
          </TextField>
        ) : (
          <Skeleton height="50px" />
        )
      }
    </Box>
  );
};